import { render, staticRenderFns } from "./recordingCourse.vue?vue&type=template&id=7dd414ce&scoped=true&"
import script from "./recordingCourse.vue?vue&type=script&lang=js&"
export * from "./recordingCourse.vue?vue&type=script&lang=js&"
import style0 from "./recordingCourse.vue?vue&type=style&index=0&id=7dd414ce&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dd414ce",
  null
  
)

export default component.exports